// * { background-color: rgba(255,0,0,.2); }
// * * { background-color: rgba(0,255,0,.2); }
// * * * { background-color: rgba(0,0,255,.2); }
// * * * * { background-color: rgba(255,0,255,.2); }
// * * * * * { background-color: rgba(0,255,255,.2); }
// * * * * * * { background-color: rgba(255,255,0,.2); }
// * * * * * * * { background-color: rgba(255,0,0,.2); }
// * * * * * * * * { background-color: rgba(0,255,0,.2); }
// * * * * * * * * * { background-color: rgba(0,0,255,.2); }
// font-family: 'EB Garamond', serif;

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Rubik:wght@300;400&display=swap');

// body {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   animation: gradient 20s ease infinite;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// @keyframes gradient {
//   0% {
//     background-position: 0%;
//   }
//   50% {
//     background-position: 100%;
//   }
//   100% {
//     background-position: 0%;
//   }
// }

// h1 {
//   color: white;
//   font-family: Open Sans, sans-serif;
//   font-weight: 300;
//   font-size: 5rem;
//   letter-spacing:  1rem;
// }

// // Skills 

// @import url('https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css');
// .devicon-javascript-plain {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-javascript-plain:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };

// @import url('https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css');
// .devicon-python-plain {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-python-plain:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };

// .devicon-html5-plain {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-html5-plain:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };
// .devicon-css3-plain {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-css3-plain:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };
// .devicon-sass-plain {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-sass-plain:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };
// .devicon-react-original {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-react-original:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };
// .devicon-nodejs-plain {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-nodejs-plain:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };
// .devicon-express-original {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-express-original:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };

// .devicon-webpack-plain {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-webpack-plain:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };

// .devicon-django-plain {
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .devicon-django-plain:hover {
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };

// // @import url('src/styles/icons/f3d4.svg');
// .fab {
//   content: "f3d4";
//   font-family: "Font Awesome 5 Brands";
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .fab:hover {
//   content: "f3d4";
//   font-family: "Font Awesome 5 Brands";
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };

// .fa-npm {
//   content: "f3d4";
//   font-family: "Font Awesome 5 Brands";
//   -webkit-font-smoothing: antialiased;
//   background: linear-gradient(-45deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
//   background-size:  400% 400%;
//   font-size: 5rem;
//   animation: gradient 2s infinite;
//   cursor: pointer
// };
// .fa-npm:hover {
//   content: "f3d4";
//   font-family: "Font Awesome 5 Brands";
//   transform: translateX(10px);
//   color: rgb(0, 0, 0);
//   border-bottom: 2px solid rgba(0, 0, 0, 1);
//   border-top: 1px solid rgba(0, 0, 0, 0.1);
// };

.homepage{
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  .mediaViewInfo {
    --web-view-name: GA-Home-Full-Hero;
    --web-view-id: GA_Home_Full_Hero;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-scale-on-double-click: true;
    --web-navigate-on-keypress: true;
    --web-application: true;
    --web-enable-deep-linking: true;
  }
  :root {
    --web-view-ids: GA_Home_Full_Hero;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
  }
  .GA_Home_Full_Hero_Class {
    position: absolute;
    width: 100%;
    height: 1457px;
    background-color: rgba(247,249,249,1);
    // overflow: hidden;
    --web-view-name: GA-Home-Full-Hero;
    --web-view-id: GA_Home_Full_Hero;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-scale-on-double-click: true;
    --web-navigate-on-keypress: true;
    --web-application: true;
    --web-enable-deep-linking: true;
  }
  .Component_15___1_Class {
    position: absolute;
    width: 1920px;
    height: 720px;
    left: -342px;
    top: 0px;
    overflow: visible;
  }
  .Hero_Image_Class {
    position: absolute;
    width: 1920px;
    height: 720px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Rectangle_1_Class {
    opacity: 0.997;
  }
  .Rectangle_1 {
    position: absolute;
    overflow: visible;
    width: auto;
    height: auto;
    left: 0px;
    top: 0px;
  }
 
  .Rectangle_2_p_Class {
    opacity: 0.082;
  }
  .Rectangle_2_p {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: auto;
    left: 0px;
    top: 0px;
  }
  .Hero_Text_Class {
    position: absolute;
    width: 425px;
    height: 265px;
    left: 769px;
    top: 228px;
    overflow: visible;
  }
  .Logo_Class {
    position: absolute;
    width: 196px;
    height: 88px;
    left: 114px;
    top: 133px;
    overflow: visible;
  }
  .Antonica_Class {
    position: absolute;
    width: 196px;
    height: 48px;
    left: 0px;
    top: 40px;
    overflow: visible;
  }
  .A_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .N_Class {
    position: absolute;
    left: 26px;
    top: 0px;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .T_Class {
    position: absolute;
    left: 56px;
    top: 0px;
    overflow: visible;
    width: 21px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .O_Class {
    position: absolute;
    left: 77px;
    top: 0px;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .N_x_Class {
    position: absolute;
    left: 104px;
    top: 0px;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .I_Class {
    position: absolute;
    left: 134px;
    top: 0px;
    overflow: visible;
    width: 13px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .C_Class {
    position: absolute;
    left: 148px;
    top: 0px;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .A__Class {
    position: absolute;
    left: 172px;
    top: 0px;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .Gabriel_Class {
    position: absolute;
    width: 160px;
    height: 48px;
    left: 18px;
    top: 0px;
    overflow: visible;
  }
  .G_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .A_ba_Class {
    position: absolute;
    left: 27px;
    top: 0px;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .B_Class {
    position: absolute;
    left: 53px;
    top: 0px;
    overflow: visible;
    width: 24px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .R_Class {
    position: absolute;
    left: 78px;
    top: 0px;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .I__Class {
    position: absolute;
    left: 104px;
    top: 0px;
    overflow: visible;
    width: 13px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .E_Class {
    position: absolute;
    left: 118px;
    top: 0px;
    overflow: visible;
    width: 21px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .L_Class {
    position: absolute;
    left: 143px;
    top: 0px;
    overflow: visible;
    width: 18px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(247,249,249,1);
  }
  .Icon_5_way_Class {
    position: absolute;
    width: 68px;
    height: 128px;
    left: 178px;
    top: 0px;
    overflow: visible;
  }
  .G_ba_Class {
    position: absolute;
    left: 4px;
    top: 4px;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 100px;
    color: rgba(247,249,249,1);
  }
  .G_bb_Class {
    position: absolute;
    left: 8px;
    top: 8px;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 100px;
    color: rgba(247,249,249,1);
  }
  .G_bc_Class {
    position: absolute;
    left: 8px;
    top: 0px;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 100px;
    color: rgba(247,249,249,1);
  }
  .G_bd_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 100px;
    color: rgba(247,249,249,1);
  }
  .G_be_Class {
    position: absolute;
    left: 0px;
    top: 8px;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 100px;
    color: rgba(247,249,249,1);
  }
  .Technical_Project_Manager___Ju_Class {
    position: absolute;
    left: 0px;
    top: 241px;
    overflow: visible;
    width: 426px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(247,249,249,1);
  }
  .Nav_bar_Class {
    position: absolute;
    width: 639px;
    height: 24px;
    left: 311px;
    top: 679px;
    overflow: visible;
  }
  .ABOUT_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROJECTS_Class {
    position: absolute;
    left: 543px;
    top: 0px;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROFFESIONAL_EXPERIENCE_Class {
    position: absolute;
    left: 165px;
    top: 0px;
    overflow: visible;
    width: 281px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .About_Content_Class {
    position: absolute;
    width: 901px;
    height: 357px;
    left: 210px;
    top: 805px;
    overflow: visible;
  }
  .Dedicated_professional_with_ex_Class {
    position: absolute;
    left: 240px;
    top: 70px;
    overflow: hidden;
    width: 662px;
    height: 287px;
    line-height: 25px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .About_Title_Class {
    position: absolute;
    width: 139.48px;
    height: 48px;
    left: 258.52px;
    top: 0px;
    overflow: visible;
  }
  .About_Class {
    position: absolute;
    left: 36.48px;
    top: 0px;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Headshot_Class {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0px;
    top: 11px;
    overflow: visible;
  }
  .Component_14___1_Class {
    position: absolute;
    width: 976px;
    height: 48px;
    left: 157px;
    top: 1264px;
    overflow: visible;
  }
  .Rectangle_18 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 200px;
    height: 66px;
    left: 794px;
    top: 0px;
  }
  .Rectangle_17 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 179px;
    height: 66px;
    left: 556px;
    top: 0px;
  }
  .Rectangle_16 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 203px;
    height: 66px;
    left: 290px;
    top: 0px;
  }
  .Rectangle_15 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 232px;
    height: 66px;
    left: 0px;
    top: 0px;
  }
  .Component_4___1_Class {
    position: absolute;
    width: 949px;
    height: 48px;
    left: 16px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Content_Class {
    position: absolute;
    width: 183px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_Class {
    position: absolute;
    width: 183px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .LinkedIn_b_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 147px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_b_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_b {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_b {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_b {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .LinkedIn_b_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 147px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Skills_Content_b_Class {
    position: absolute;
    width: 154px;
    height: 48px;
    left: 291px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_ca_Class {
    position: absolute;
    width: 154px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Github_cg_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_ch_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ci {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_cj {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_ck {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Github_cg_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_ch_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ci {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_cj {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_ck {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Skills_Content_cl_Class {
    position: absolute;
    width: 132px;
    height: 48px;
    left: 553px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_cm_Class {
    position: absolute;
    width: 132px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Hint_logo_cs_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ct {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_cu {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_cv {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Email_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_cs_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ct {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_cu {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_cv {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Skills_Content_cw_Class {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 793px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_cx_Class {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Hint_logo_c_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_c {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_da {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_da {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Twitter_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_c_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_c {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_da {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_da {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
}


//About Section below:
.about{
  .mediaViewInfo {
    --web-view-name: GA-About-Sticky-Head;
    --web-view-id: GA_About_Sticky_Head;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-scale-on-double-click: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
  }
  :root {
    --web-view-ids: GA_About_Sticky_Head;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
  }
  .GA_About_Sticky_Head_Class {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 1290px;
    height: 1935px;
    background-color: rgba(247,249,249,1);
    overflow: hidden;
    --web-view-name: GA-About-Sticky-Head;
    --web-view-id: GA_About_Sticky_Head;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-scale-on-double-click: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
  }
  .About_Content_Class {
    position: absolute;
    width: 1164px;
    height: 440px;
    left: 63px;
    top: 110px;
    overflow: visible;
  }
  .I_am_an_ACTIVATOR___When_can_w_Class {
    position: absolute;
    left: 243px;
    top: 62px;
    overflow: hidden;
    width: 922px;
    height: 378px;
    line-height: 25px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .About_Title_Class {
    position: absolute;
    width: 140.31px;
    height: 48px;
    left: 239.69px;
    top: 0px;
    overflow: visible;
  }
  .About_Class {
    position: absolute;
    left: 37.31px;
    top: 0px;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_Class {
    position: absolute;
    width: 24px;
    height: 18px;
    left: 0px;
    top: 15.5px;
    overflow: visible;
  }
  .Line_5 {
    overflow: visible;
    position: absolute;
    width: 24.608px;
    height: 11.608px;
    left: 2px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_2 {
    overflow: visible;
    position: absolute;
    width: 26px;
    height: 2px;
    left: 0px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_4 {
    overflow: visible;
    position: absolute;
    width: 24.608px;
    height: 11.608px;
    left: 2px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Headshot_Class {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 0px;
    top: 12px;
    overflow: visible;
  }
  .Header_Class {
    position: absolute;
    width: 1290px;
    height: 86px;
    left: 0px;
    top: -3px;
    overflow: visible;
  }
  .Mask_Group_1_Class {
    position: absolute;
    width: 1290px;
    height: 81px;
    left: 0px;
    top: 3px;
    overflow: visible;
  }
  .GA_icon_5_way_2x_Class {
    position: absolute;
    width: 46px;
    height: 86px;
    left: 37px;
    top: 0px;
    overflow: visible;
  }
  .Nav_bar_Class {
    position: absolute;
    width: 523px;
    height: 26px;
    left: 707px;
    top: 31px;
    overflow: visible;
  }
  .Rectangle_3 {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 2px;
    left: 19px;
    top: 24px;
  }
  .ABOUT_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROJECTS_Class {
    position: absolute;
    left: 427px;
    top: 0px;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROFFESIONAL_EXPERIENCE_Class {
    position: absolute;
    left: 107px;
    top: 0px;
    overflow: visible;
    width: 281px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .Component_12___1_Class {
    position: absolute;
    width: 1164px;
    height: 296px;
    left: 63px;
    top: 989px;
    overflow: visible;
  }
  .Rectangle_13 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1182px;
    height: 314px;
    left: 0px;
    top: 0px;
  }
  .Component_6___1_Class {
    position: absolute;
    width: 1120px;
    height: 289.698px;
    left: 29px;
    top: 6px;
    overflow: visible;
  }
  .Skills_Content_Class {
    position: absolute;
    width: 383.052px;
    height: 289.698px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_Class {
    position: absolute;
    width: 310.052px;
    height: 45px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Area_of_Expertise_Class {
    position: absolute;
    left: 37.052px;
    top: 0px;
    overflow: visible;
    width: 274px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 38px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_bd_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_be {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_bf {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_bg {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Group_2_Class {
    position: absolute;
    width: 364px;
    height: 226.052px;
    left: 19.052px;
    top: 63.645px;
    overflow: visible;
  }
  .Component_1___1_Class {
    position: absolute;
    width: 364px;
    height: 226.052px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Project_Management__Software_D_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 365px;
    height: 226.052px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Skills_Content_bk_Class {
    position: absolute;
    width: 341px;
    height: 211px;
    left: 779px;
    top: 57px;
    overflow: visible;
  }
  .Group_1_Class {
    position: absolute;
    width: 341px;
    height: 211px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Software_Development_Object_Or_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
    width: 342px;
    height: 211px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Component_11___1_Class {
    position: absolute;
    width: 1164px;
    height: 365px;
    left: 63px;
    top: 587px;
    overflow: visible;
  }
  .Rectangle_12 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1182px;
    height: 383px;
    left: 0px;
    top: 0px;
  }
  .Component_7___1_Class {
    position: absolute;
    width: 1117px;
    height: 351px;
    left: 28px;
    top: 8px;
    overflow: visible;
  }
  .Skills_Content_bq_Class {
    position: absolute;
    width: 1115px;
    height: 325px;
    left: 2px;
    top: 26px;
    overflow: visible;
  }
  .______Google_Technical_Writing_Class {
    position: absolute;
    left: 0px;
    top: 49px;
    overflow: hidden;
    width: 424px;
    height: 276px;
    text-align: center;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .__Masters_in_Commercial_Risk_M_Class {
    position: absolute;
    left: 653px;
    top: 0px;
    overflow: hidden;
    width: 463px;
    height: 325px;
    text-align: center;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Skills_Content_bt_Class {
    position: absolute;
    width: 427.052px;
    height: 45px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_bu_Class {
    position: absolute;
    width: 427.052px;
    height: 45px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Education___Credentials_Class {
    position: absolute;
    left: 37.052px;
    top: 0px;
    overflow: visible;
    width: 391px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 38px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_bw_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_bx {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_by {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_bz {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Component_13___1_Class {
    position: absolute;
    width: 1164px;
    height: 471px;
    left: 66px;
    top: 1322px;
    overflow: visible;
  }
  .Rectangle_14 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1182px;
    height: 489px;
    left: 0px;
    top: 0px;
  }
  .Component_10___1_Class {
    position: absolute;
    width: 1061.5px;
    height: 445px;
    left: 27.5px;
    top: 4px;
    overflow: visible;
  }
  .Skill_Class {
    position: absolute;
    width: 487px;
    height: 368px;
    left: 18.5px;
    top: 77px;
    overflow: visible;
  }
  .Skill_b_Class {
    position: absolute;
    width: 487px;
    height: 358px;
    left: 0px;
    top: 0px;
    overflow: hidden;
  }
  .Skill_ca_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 1px;
    top: 0px;
    overflow: visible;
  }
  .Rectangle_5 {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_cb_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 131px;
    top: 0px;
    overflow: visible;
  }
  .Rectangle_5_b {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_ca_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_cc_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 261px;
    top: 0px;
    overflow: visible;
  }
  .Rectangle_5_cc {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_cd_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_ce_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 391px;
    top: 0px;
    overflow: visible;
  }
  .Rectangle_5_cf {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_cg_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_ch_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 1px;
    top: 93px;
    overflow: visible;
  }
  .Rectangle_5_ci {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_cj_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_ck_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 131px;
    top: 93px;
    overflow: visible;
  }
  .Rectangle_5_cl {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_cm_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_cn_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 261px;
    top: 93px;
    overflow: visible;
  }
  .Rectangle_5_co {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_cp_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_cq_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 391px;
    top: 93px;
    overflow: visible;
  }
  .Rectangle_5_cr {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_cs_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_ct_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 1px;
    top: 186px;
    overflow: visible;
  }
  .Rectangle_5_cu {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_cv_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_cw_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 131px;
    top: 186px;
    overflow: visible;
  }
  .Rectangle_5_cx {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_cy_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_cz_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 261px;
    top: 186px;
    overflow: visible;
  }
  .Rectangle_5_c {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_c_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_c_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 391px;
    top: 186px;
    overflow: visible;
  }
  .Rectangle_5_da {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_da_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_da_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 1px;
    top: 279px;
    overflow: visible;
  }
  .Rectangle_5_db {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_db_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_db_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 131px;
    top: 279px;
    overflow: visible;
  }
  .Rectangle_5_dc {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_dc_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_dc_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 261px;
    top: 279px;
    overflow: visible;
  }
  .Rectangle_5_dd {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_dd_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skill_de_Class {
    position: absolute;
    width: 68px;
    height: 73px;
    left: 391px;
    top: 279px;
    overflow: visible;
  }
  .Rectangle_5_df {
    position: absolute;
    overflow: visible;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 0px;
  }
  .Language_dg_Class {
    position: absolute;
    left: 0px;
    top: 52px;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(110,131,135,1);
  }
  .Skills___Tools_Class {
    position: absolute;
    left: 32.5px;
    top: 0px;
    overflow: visible;
    width: 226px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_di_Class {
    position: absolute;
    width: 24px;
    height: 18px;
    left: 0px;
    top: 15.5px;
    overflow: visible;
  }
  .Line_5_dj {
    overflow: visible;
    position: absolute;
    width: 25.591px;
    height: 11.591px;
    left: 1px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_2_dk {
    overflow: visible;
    position: absolute;
    width: 26px;
    height: 2px;
    left: 0px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_4_dl {
    overflow: visible;
    position: absolute;
    width: 24.608px;
    height: 11.608px;
    left: 2px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Component_8___1_Class {
    position: absolute;
    width: 276px;
    height: 304px;
    left: 785.5px;
    top: 78px;
    overflow: visible;
  }
  .G_Suite_Microsoft_365_Visual_S_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
    width: 277px;
    height: 304px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Component_14___2_Class {
    position: absolute;
    width: 976px;
    height: 48px;
    left: 157px;
    top: 1830px;
    overflow: visible;
  }
  .Rectangle_18 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 200px;
    height: 66px;
    left: 794px;
    top: 0px;
  }
  .Rectangle_17 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 179px;
    height: 66px;
    left: 556px;
    top: 0px;
  }
  .Rectangle_16 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 203px;
    height: 66px;
    left: 290px;
    top: 0px;
  }
  .Rectangle_15 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 232px;
    height: 66px;
    left: 0px;
    top: 0px;
  }
  .Component_4___1_Class {
    position: absolute;
    width: 949px;
    height: 48px;
    left: 16px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Content_du_Class {
    position: absolute;
    width: 183px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_dv_Class {
    position: absolute;
    width: 183px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .LinkedIn_d_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 147px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_dx_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_dy {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_dz {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_d {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .LinkedIn_d_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 147px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Skills_Content_d_Class {
    position: absolute;
    width: 154px;
    height: 48px;
    left: 291px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_d_Class {
    position: absolute;
    width: 154px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Github_d_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_ea_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_eb {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ec {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_ed {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Github_d_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_ea_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_eb {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ec {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_ed {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Skills_Content_ee_Class {
    position: absolute;
    width: 132px;
    height: 48px;
    left: 553px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_ef_Class {
    position: absolute;
    width: 132px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Hint_logo_el_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_em {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_en {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_eo {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Email_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_el_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_em {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_en {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_eo {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Skills_Content_ep_Class {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 793px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_eq_Class {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Hint_logo_ew_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ex {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ey {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_ez {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Twitter_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_ew_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ex {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ey {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_ez {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .GA_icon_5_way_2x_b_Class {
    position: absolute;
    width: 46px;
    height: 86px;
    left: 37px;
    top: 0px;
    overflow: visible;
  }
}
.experience{
  .mediaViewInfo {
    --web-view-name: GA - Experince;
    --web-view-id: GA___Experince;
    --web-scale-on-resize: true;
    --web-scale-on-double-click: true;
    --web-center-horizontally: true;
    --web-navigate-on-keypress: true;
    --web-enable-deep-linking: true;
  }
  :root {
    --web-view-ids: GA___Experince;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
  }
  .GA___Experince_Class {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 1290px;
    height: 2053px;
    background-color: rgba(247,249,249,1);
    overflow: hidden;
    --web-view-name: GA - Experince;
    --web-view-id: GA___Experince;
    --web-scale-on-resize: true;
    --web-scale-on-double-click: true;
    --web-center-horizontally: true;
    --web-navigate-on-keypress: true;
    --web-enable-deep-linking: true;
  }
  .About_Content_Class {
    position: absolute;
    width: 437.456px;
    height: 48px;
    left: 87.544px;
    top: 125px;
    overflow: visible;
  }
  .About_Title_Class {
    position: absolute;
    width: 437.456px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Professional_Experience_Class {
    position: absolute;
    left: 33.456px;
    top: 0px;
    overflow: visible;
    width: 405px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_Class {
    position: absolute;
    width: 24px;
    height: 18px;
    left: 0px;
    top: 14.5px;
    overflow: visible;
  }
  .Line_5 {
    overflow: visible;
    position: absolute;
    width: 24.608px;
    height: 11.608px;
    left: 2px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_2 {
    overflow: visible;
    position: absolute;
    width: 26px;
    height: 2px;
    left: 0px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_4 {
    overflow: visible;
    position: absolute;
    width: 24.608px;
    height: 11.608px;
    left: 2px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Header_b_Class {
    position: absolute;
    width: 1290px;
    height: 86px;
    left: 0px;
    top: -3px;
    overflow: visible;
  }
  .Mask_Group_1_b_Class {
    position: absolute;
    width: 1290px;
    height: 80px;
    left: 0px;
    top: 3px;
    overflow: visible;
  }
  .GA_icon_5_way_2x_b_Class {
    position: absolute;
    width: 46px;
    height: 86px;
    left: 37px;
    top: 0px;
    overflow: visible;
  }
  .Nav_bar_b_Class {
    position: absolute;
    width: 523px;
    height: 26px;
    left: 707px;
    top: 30px;
    overflow: visible;
  }
  .Rectangle_3_b {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 2px;
    left: 232px;
    top: 24px;
  }
  .ABOUT_ca_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROJECTS_cb_Class {
    position: absolute;
    left: 427px;
    top: 0px;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROFFESIONAL_EXPERIENCE_cc_Class {
    position: absolute;
    left: 107px;
    top: 0px;
    overflow: visible;
    width: 281px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .Component_16___1_Class {
    position: absolute;
    width: 1067.877px;
    height: 1110.396px;
    left: 118px;
    top: 555px;
    overflow: visible;
  }
  .Rectangle_6 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1081px;
    height: 994px;
    left: 0px;
    top: 54px;
  }
  .Skills_Title_Class {
    position: absolute;
    width: 234.5px;
    height: 39px;
    left: 14.5px;
    top: 0px;
    overflow: visible;
  }
  .Altaire_Limited_Class {
    position: absolute;
    left: 30.5px;
    top: 0px;
    overflow: visible;
    width: 205px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 32px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo__Class {
    position: absolute;
    width: 24px;
    height: 19px;
    left: 0px;
    top: 10.5px;
    overflow: visible;
  }
  .Line_5_ {
    overflow: visible;
    position: absolute;
    width: 25.632px;
    height: 12.632px;
    left: 1px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_2_ {
    overflow: visible;
    position: absolute;
    width: 26px;
    height: 2px;
    left: 0px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_4_ba {
    overflow: visible;
    position: absolute;
    width: 24.648px;
    height: 12.648px;
    left: 2px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Head_of_Planning_______________Class {
    position: absolute;
    left: 14px;
    top: 65px;
    overflow: hidden;
    width: 1014px;
    height: 334px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Skills_Content_Class {
    position: absolute;
    width: 999px;
    height: 317px;
    left: 16px;
    top: 428px;
    overflow: visible;
  }
  .Business_Process_Architect_____Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
    width: 1000px;
    height: 317px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Skills_Content_be_Class {
    position: absolute;
    width: 999px;
    height: 258px;
    left: 15px;
    top: 772px;
    overflow: visible;
  }
  .Digital_Project_Manager________Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
    width: 1000px;
    height: 258px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Path_1 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    overflow: visible;
    position: absolute;
    width: 1085.663px;
    height: 1074.396px;
    left: 0.214px;
    top: 54px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Skills_Content_bh_Class {
    position: absolute;
    width: 999px;
    height: 330px;
    left: 28px;
    top: 78px;
    overflow: visible;
  }
  .Head_of_Planning_______________bi_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
    width: 1000px;
    height: 330px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Skills_Content_bj_Class {
    position: absolute;
    width: 1006px;
    height: 335px;
    left: 21px;
    top: 428px;
    overflow: visible;
  }
  .Business_Process_Architect_____bk_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
    width: 1007px;
    height: 335px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Skills_Content_bl_Class {
    position: absolute;
    width: 1006px;
    height: 332px;
    left: 21px;
    top: 772px;
    overflow: visible;
  }
  .Digital_Project_Manager________bm_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 1007px;
    height: 332px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Component_18___1_Class {
    position: absolute;
    width: 1098.455px;
    height: 214px;
    left: 87.544px;
    top: 1701px;
    overflow: visible;
  }
  .Skills_Content_bo_Class {
    position: absolute;
    width: 999px;
    height: 126px;
    left: 36.456px;
    top: 75px;
    overflow: visible;
  }
  .Business_Developer___Corporate_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 1000px;
    height: 126px;
    line-height: 30px;
    margin-top: -5px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .About_Content_bq_Class {
    position: absolute;
    width: 631.456px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .About_Title_br_Class {
    position: absolute;
    width: 631.456px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Additional_Professional_Experi_Class {
    position: absolute;
    left: 37.456px;
    top: 0px;
    overflow: visible;
    width: 595px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_bt_Class {
    position: absolute;
    width: 24px;
    height: 18px;
    left: 0px;
    top: 15.5px;
    overflow: visible;
  }
  .Line_5_bu {
    overflow: visible;
    position: absolute;
    width: 24.608px;
    height: 11.608px;
    left: 2px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_2_bv {
    overflow: visible;
    position: absolute;
    width: 26px;
    height: 2px;
    left: 0px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_4_bw {
    overflow: visible;
    position: absolute;
    width: 24.608px;
    height: 11.608px;
    left: 2px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Rectangle_9 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1086px;
    height: 167px;
    left: 30.456px;
    top: 65px;
  }
  .Skills_Content_by_Class {
    position: absolute;
    width: 999px;
    height: 126px;
    left: 46.456px;
    top: 82px;
    overflow: visible;
  }
  .Business_Developer___Corporate_bz_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 1000px;
    height: 126px;
    line-height: 30px;
    margin-top: -5px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Header_b_Class {
    position: absolute;
    width: 1290px;
    height: 86px;
    left: 0px;
    top: -3px;
    overflow: visible;
  }
  .Mask_Group_1_b_Class {
    position: absolute;
    width: 1290px;
    height: 80px;
    left: 0px;
    top: 3px;
    overflow: visible;
  }
  .GA_icon_5_way_2x_b_Class {
    position: absolute;
    width: 46px;
    height: 86px;
    left: 37px;
    top: 0px;
    overflow: visible;
  }
  .Nav_bar_b_Class {
    position: absolute;
    width: 523px;
    height: 26px;
    left: 707px;
    top: 30px;
    overflow: visible;
  }
  .Rectangle_3_b {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 2px;
    left: 232px;
    top: 24px;
  }
  .ABOUT_ca_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROJECTS_cb_Class {
    position: absolute;
    left: 427px;
    top: 0px;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROFFESIONAL_EXPERIENCE_cc_Class {
    position: absolute;
    left: 107px;
    top: 0px;
    overflow: visible;
    width: 281px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .Component_17___1_Class {
    position: absolute;
    width: 1068px;
    height: 345px;
    left: 118px;
    top: 189px;
    overflow: visible;
  }
  .Rectangle_7 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1081px;
    height: 305px;
    left: 0px;
    top: 53px;
  }
  .Skills_Content_cf_Class {
    position: absolute;
    width: 999.5px;
    height: 345px;
    left: 12.5px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_cg_Class {
    position: absolute;
    width: 274.5px;
    height: 39px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .General_Assembly_Class {
    position: absolute;
    left: 30.5px;
    top: 0px;
    overflow: visible;
    width: 245px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 32px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_ci_Class {
    position: absolute;
    width: 24px;
    height: 19px;
    left: 0px;
    top: 10.5px;
    overflow: visible;
  }
  .Line_5_cj {
    overflow: visible;
    position: absolute;
    width: 25.632px;
    height: 12.632px;
    left: 1px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_2_ck {
    overflow: visible;
    position: absolute;
    width: 26px;
    height: 2px;
    left: 0px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Line_4_cl {
    overflow: visible;
    position: absolute;
    width: 24.648px;
    height: 12.648px;
    left: 2px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
  }
  .Software_Engineering_Immersive_Class {
    position: absolute;
    left: 0.5px;
    top: 67px;
    overflow: hidden;
    width: 1000px;
    height: 278px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Rectangle_11 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1086px;
    height: 310px;
    left: 0px;
    top: 53px;
  }
  .Skills_Content_co_Class {
    position: absolute;
    width: 999px;
    height: 278px;
    left: 28px;
    top: 61px;
    overflow: visible;
  }
  .Software_Engineering_Immersive_cp_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
    width: 1000px;
    height: 278px;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
  }
  .Component_14___4_Class {
    position: absolute;
    width: 976px;
    height: 48px;
    left: 157px;
    top: 1951px;
    overflow: visible;
  }
  .Rectangle_18 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 200px;
    height: 66px;
    left: 794px;
    top: 0px;
  }
  .Rectangle_17 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 179px;
    height: 66px;
    left: 556px;
    top: 0px;
  }
  .Rectangle_16 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 203px;
    height: 66px;
    left: 290px;
    top: 0px;
  }
  .Rectangle_15 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 232px;
    height: 66px;
    left: 0px;
    top: 0px;
  }
  .Component_4___1_Class {
    position: absolute;
    width: 949px;
    height: 48px;
    left: 16px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Content_cw_Class {
    position: absolute;
    width: 183px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_cx_Class {
    position: absolute;
    width: 183px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .LinkedIn_c_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 147px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_cz_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_c {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_c {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_c {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .LinkedIn_c_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 147px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Skills_Content_c_Class {
    position: absolute;
    width: 154px;
    height: 48px;
    left: 291px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_c_Class {
    position: absolute;
    width: 154px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Github_db_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_dc_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_dd {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_de {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_df {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Github_db_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_dc_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_dd {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_de {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_df {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Skills_Content_dg_Class {
    position: absolute;
    width: 132px;
    height: 48px;
    left: 553px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_dh_Class {
    position: absolute;
    width: 132px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Hint_logo_dn_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_do {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_dp {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_dq {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Email_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_dn_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_do {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_dp {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_dq {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Skills_Content_dr_Class {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 793px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_ds_Class {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Hint_logo_dy_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_dz {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_d {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_d {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Twitter_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_dy_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_dz {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_d {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_d {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
}
.projects{
  .mediaViewInfo {
    --web-view-name: GA-Projects-Sticky-Head;
    --web-view-id: GA_Projects_Sticky_Head;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-application: true;
    --web-enable-deep-linking: true;
  }
  :root {
    --web-view-ids: GA_Projects_Sticky_Head;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
  }
  .GA_Projects_Sticky_Head_Class {
    position: absolute;
    width: 1290px;
    height: 2392px;
    background-color: rgba(247,249,249,1);
    overflow: hidden;
    --web-view-name: GA-Projects-Sticky-Head;
    --web-view-id: GA_Projects_Sticky_Head;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-application: true;
    --web-enable-deep-linking: true;
  }
  .Project_Content_Class {
    position: absolute;
    width: 1181.515px;
    height: 2152.515px;
    left: 72px;
    top: 104px;
    overflow: visible;
  }
  .About_Title_Class {
    position: absolute;
    width: 173px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Projects_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Project_Class {
    position: absolute;
    width: 1181.015px;
    height: 2119.515px;
    left: 0.5px;
    top: 33px;
    overflow: hidden;
  }
  .Project_r_Class {
    position: absolute;
    width: 1226px;
    height: 479px;
    left: -62.5px;
    top: 46px;
    overflow: visible;
  }
  .Image_1_Class {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.09));
    position: absolute;
    width: 869.5px;
    height: 470px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Completed_as_Project_4_during__Class {
    position: absolute;
    left: 904px;
    top: 2px;
    overflow: visible;
    width: 323px;
    height: 477px;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(110,131,135,1);
  }
  .Project_u_Class {
    position: absolute;
    width: 1226px;
    height: 479px;
    left: -62.5px;
    top: 570px;
    overflow: visible;
  }
  .Image_1_v_Class {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.09));
    position: absolute;
    width: 869.5px;
    height: 470px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Completed_as_Project_3_during__Class {
    position: absolute;
    left: 904px;
    top: 2px;
    overflow: visible;
    width: 323px;
    height: 477px;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(110,131,135,1);
  }
  .Project_x_Class {
    position: absolute;
    width: 1226px;
    height: 479px;
    left: -62.5px;
    top: 1094px;
    overflow: visible;
  }
  .Image_1_y_Class {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.09));
    position: absolute;
    width: 869.5px;
    height: 470px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Completed_as_Project_2_during__Class {
    position: absolute;
    left: 904px;
    top: 2px;
    overflow: visible;
    width: 323px;
    height: 477px;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(110,131,135,1);
  }
  .Project__Class {
    position: absolute;
    width: 1226px;
    height: 479px;
    left: -62.5px;
    top: 1618px;
    overflow: visible;
  }
  .Image_1__Class {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.09));
    position: absolute;
    width: 869.5px;
    height: 470px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Completed_as_Project_1_during__Class {
    position: absolute;
    left: 904px;
    top: 2px;
    overflow: hidden;
    width: 323px;
    height: 477px;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(110,131,135,1);
  }
  .Project_ba_Class {
    position: absolute;
    width: 1226px;
    height: 479px;
    left: -62.5px;
    top: 2142px;
    overflow: visible;
  }
  .Image_1_ba_Class {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.09));
    position: absolute;
    width: 869.5px;
    height: 470px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Short_description_about_the_pr_Class {
    position: absolute;
    left: 904px;
    top: 2px;
    overflow: visible;
    width: 323px;
    height: 477px;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(110,131,135,1);
  }
  .Header_Class {
    position: absolute;
    width: 1290px;
    height: 86px;
    left: 0px;
    top: -3px;
    overflow: visible;
  }
  .Mask_Group_1_Class {
    position: absolute;
    width: 1290px;
    height: 80px;
    left: 0px;
    top: 3px;
    overflow: visible;
  }
  .GA_icon_5_way_2x_Class {
    position: absolute;
    width: 46px;
    height: 86px;
    left: 37px;
    top: 0px;
    overflow: visible;
  }
  .Nav_bar_Class {
    position: absolute;
    width: 523px;
    height: 26px;
    left: 707px;
    top: 30px;
    overflow: visible;
  }
  .Rectangle_3 {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 2px;
    left: 460px;
    top: 24px;
  }
  .ABOUT_Class {
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROJECTS_Class {
    position: absolute;
    left: 427px;
    top: 0px;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .PROFFESIONAL_EXPERIENCE_Class {
    position: absolute;
    left: 107px;
    top: 0px;
    overflow: visible;
    width: 281px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(247,249,249,1);
    letter-spacing: 1px;
  }
  .Component_14___5_Class {
    position: absolute;
    width: 976px;
    height: 48px;
    left: 157px;
    top: 2297px;
    overflow: visible;
  }
  .Rectangle_18 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 200px;
    height: 66px;
    left: 794px;
    top: 0px;
  }
  .Rectangle_17 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 179px;
    height: 66px;
    left: 556px;
    top: 0px;
  }
  .Rectangle_16 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 203px;
    height: 66px;
    left: 290px;
    top: 0px;
  }
  .Rectangle_15 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 232px;
    height: 66px;
    left: 0px;
    top: 0px;
  }
  .Component_4___1_Class {
    position: absolute;
    width: 949px;
    height: 48px;
    left: 16px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Content_Class {
    position: absolute;
    width: 183px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_Class {
    position: absolute;
    width: 183px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .LinkedIn_bw_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 147px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_bs_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_bt {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_bu {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_bv {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .LinkedIn_bw_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 147px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Skills_Content_bx_Class {
    position: absolute;
    width: 154px;
    height: 48px;
    left: 291px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_by_Class {
    position: absolute;
    width: 154px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Github_b_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_ca_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ca {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ca {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_ca {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Github_b_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_ca_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ca {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ca {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_ca {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Skills_Content_b_Class {
    position: absolute;
    width: 132px;
    height: 48px;
    left: 553px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_ca_Class {
    position: absolute;
    width: 132px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Hint_logo_cg_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ch {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ci {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_cj {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Email_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_cg_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_ch {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ci {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_cj {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Skills_Content_ck_Class {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 793px;
    top: 0px;
    overflow: visible;
  }
  .Skills_Title_cl_Class {
    position: absolute;
    width: 156px;
    height: 48px;
    left: 0px;
    top: 0px;
    overflow: visible;
  }
  .Hint_logo_cr_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_cs {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ct {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_cu {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Twitter_Class {
    position: absolute;
    left: 37px;
    top: 0px;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Alegreya Sans;
    font-style: normal;
    font-weight: lighter;
    font-size: 40px;
    color: rgba(110,131,135,1);
    letter-spacing: 0.5px;
  }
  .Hint_logo_cr_Class {
    position: absolute;
    width: 24.173px;
    height: 18.184px;
    left: 0px;
    top: 15px;
    overflow: visible;
  }
  .Line_5_cs {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0865,1.5922) rotate(67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_2_ct {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,12,-3) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Line_4_cu {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    transform: translate(0px, 0px) matrix(1,0,0,1,13.0866,-7.4078) rotate(-67.5deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
  }
  .Mask_Group_1_b_Class {
    position: absolute;
    width: 1290px;
    height: 80px;
    left: 0px;
    top: 3px;
    overflow: visible;
  }
  .GA_icon_5_way_2x_b_Class {
    position: absolute;
    width: 46px;
    height: 86px;
    left: 37px;
    top: 0px;
    overflow: visible;
  }
}